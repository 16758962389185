@charset "utf-8";

$primary-color: #ff3300;
$hover-color: #00ccff;
$hover-background-color: $primary-color;
$divider-color: #d9d9d9;

$base-font-family: -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", Arial, sans-serif !default;
$base-font-size:   21px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #333333 !default;
$background-color: #ffffff !default;
$background-color-alt: #292929 !default;
$brand-color:      #2a7ae2 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          732px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width:         calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
  }
}

/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

html {
  font-family: $base-font-family;
  color: $text-color;
  background-color: $background-color;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

body {
  margin: 0;
}

ul {
  padding: 0;
}

a {
  color: $primary-color;
  text-decoration: none;

  &:hover {
    color: $hover-color
  }
}

.username {
  margin-left: 5px;
}


.flex {
  display: flex;
}

.panel {
  flex: 1
}
